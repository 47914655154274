import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Data Science Methods`}</h1>
    <ul>
      <li parentName="ul">{`This page is a stub and will be expanded. In the meantime:`}
        <ul parentName="li">
          <li parentName="ul">{`Here are `}<a parentName="li" {...{
              "href": "/open-source",
              "title": "open source"
            }}>{`open source`}</a>{` `}<a parentName="li" {...{
              "href": "/notebooks",
              "title": "notebooks"
            }}>{`notebooks`}</a>{` in Python`}</li>
          <li parentName="ul">{`Here is `}<a parentName="li" {...{
              "href": "/open-data",
              "title": "open data"
            }}>{`open data`}</a>{` to play with from our `}<a parentName="li" {...{
              "href": "/Case-studies",
              "title": "⚗️ Case studies"
            }}>{`⚗️ Case studies`}</a>{``}</li>
          <li parentName="ul">{`Here's a schema of our data `}<a parentName="li" {...{
              "href": "/export",
              "title": "export"
            }}>{`export`}</a>{``}</li>
          <li parentName="ul">{`Here is standard `}<a parentName="li" {...{
              "href": "/Analysis",
              "title": "🔬 Analysis"
            }}>{`🔬 Analysis`}</a>{``}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      